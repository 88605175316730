var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper-registro cover-login"},[_c('div',{staticClass:"popup"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var valid = ref.valid;
return [_c('form',{staticClass:"paso row-between fww",attrs:{"valid":valid,"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('h2',{staticClass:"titulo left"},[_vm._v("Iniciar sesión")]),_c('div',{staticClass:"contenedor-input"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.document),expression:"data.document"}],class:classes,attrs:{"autocomplete":"off","type":"text","name":"mail","placeholder":"DNI"},domProps:{"value":(_vm.data.document)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "document", $event.target.value)}}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input"},[_c('ValidationProvider',{attrs:{"rules":'required|min:8',"vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.password),expression:"data.password"}],class:classes,attrs:{"autocomplete":"off","type":"password","name":"pass","placeholder":"Contraseña"},domProps:{"value":(_vm.data.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "password", $event.target.value)}}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"full-width row-center"},[_c('button',{staticClass:"boton-azul",attrs:{"loading":_vm.loading}},[_vm._v("Acceder")])]),_c('router-link',{staticClass:"recordar-pass",attrs:{"to":"/solicitar-contrasena"}},[_vm._v("He olvidado mi contraseña")]),_c('router-link',{staticClass:"acceso-registro",attrs:{"id":"aRegistro","to":"/es/general/registro"}},[_c('span',[_vm._v("¿Aún no estás registrado?")])])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }