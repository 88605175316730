var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper-registro cover-login"},[_c('div',{staticClass:"popup"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var valid = ref.valid;
return [_c('form',{staticClass:"paso row-between fww",attrs:{"valid":valid,"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('h2',{staticClass:"titulo left"},[_vm._v("Mis datos personales")]),_c('div',{staticClass:"imagen-input-container"},[_c('p',[_vm._v("Mi foto de perfil")]),_c('avatar',{model:{value:(_vm.data.image),callback:function ($$v) {_vm.$set(_vm.data, "image", $$v)},expression:"data.image"}})],1),_c('div',{staticClass:"contenedor-input half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.name),expression:"data.name"}],class:classes,attrs:{"autocomplete":"off","type":"text","name":"nombre","placeholder":"Nombre"},domProps:{"value":(_vm.data.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "name", $event.target.value)}}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.surname),expression:"data.surname"}],class:classes,attrs:{"autocomplete":"off","type":"text","name":"apellido-uno","placeholder":"Apellido 1"},domProps:{"value":(_vm.data.surname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "surname", $event.target.value)}}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.surname_2),expression:"data.surname_2"}],class:classes,attrs:{"autocomplete":"off","type":"text","name":"apellido-dos","placeholder":"Apellido 2"},domProps:{"value":(_vm.data.surname_2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "surname_2", $event.target.value)}}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.nationality),expression:"data.nationality"}],staticClass:"pretty",class:classes,attrs:{"id":"nacionalidad","name":"nacionalidad"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.data, "nationality", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"name":"myBrowser","placeholder":"","value":""}},[_vm._v("Nacionalidad")]),_vm._l((_vm.dominios.paises),function(pais,key){return _c('option',{key:key,attrs:{"name":"myBrowser"},domProps:{"value":pais}},[_vm._v(" "+_vm._s(pais))])})],2),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.document_type),expression:"data.document_type"}],staticClass:"pretty",class:classes,attrs:{"id":"tipodocumento","name":"tipodocumento"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.data, "document_type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"name":"myBrowser","placeholder":"","value":""}},[_vm._v("Tipo de documento")]),_vm._l((_vm.dominios.tiposdocumento),function(tipo,key){return _c('option',{key:key,attrs:{"name":"myBrowser"},domProps:{"value":key}},[_vm._v(" "+_vm._s(tipo))])})],2),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.document),expression:"data.document"}],class:classes,attrs:{"autocomplete":"off","type":"text","name":"dni","placeholder":"DNI"},domProps:{"value":(_vm.data.document)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "document", $event.target.value)}}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.email),expression:"data.email"}],class:classes,attrs:{"autocomplete":"off","type":"email","name":"mail","placeholder":"Mail"},domProps:{"value":(_vm.data.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "email", $event.target.value)}}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.phone),expression:"data.phone"}],class:classes,attrs:{"autocomplete":"off","type":"text","name":"movil","placeholder":"Móvil"},domProps:{"value":(_vm.data.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "phone", $event.target.value)}}}),_c('p',{staticClass:"msg-info-tel"},[_vm._v("Por favor no utilices ni espacios ni símbolos, si tienes un teléfono extranjero indica el prefijo con 00 en vez de con +")]),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input half row-start fww"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('h5',[_vm._v("Sexo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.gender),expression:"data.gender"}],attrs:{"type":"radio","name":"sexo","id":"hombre"},domProps:{"value":'0',"checked":_vm._q(_vm.data.gender,'0')},on:{"change":function($event){return _vm.$set(_vm.data, "gender", '0')}}}),_c('label',{class:classes,attrs:{"for":"hombre"}},[_vm._v("Hombre")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.gender),expression:"data.gender"}],attrs:{"type":"radio","name":"sexo","id":"mujer"},domProps:{"value":'1',"checked":_vm._q(_vm.data.gender,'1')},on:{"change":function($event){return _vm.$set(_vm.data, "gender", '1')}}}),_c('label',{class:classes,attrs:{"for":"mujer"}},[_vm._v("Mujer")]),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('datepicker',{class:classes,attrs:{"label":'Fecha de nacimiento',"name":'fecha'},model:{value:(_vm.data.birthday_date),callback:function ($$v) {_vm.$set(_vm.data, "birthday_date", $$v)},expression:"data.birthday_date"}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.address),expression:"data.address"}],class:classes,attrs:{"autocomplete":"off","type":"text","name":"direccion","placeholder":"Dirección"},domProps:{"value":(_vm.data.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "address", $event.target.value)}}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.country),expression:"data.country"}],staticClass:"pretty",class:classes,attrs:{"id":"paisresidencia","name":"paisresidencia"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.data, "country", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"name":"myBrowser","placeholder":"","value":""}},[_vm._v("País de residencia")]),_vm._l((_vm.dominios.paises),function(pais,key){return _c('option',{key:key,attrs:{"name":"myBrowser"},domProps:{"value":pais}},[_vm._v(" "+_vm._s(pais))])})],2),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.data.country != 'España')?_c('div',{staticClass:"contenedor-input half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.province),expression:"data.province"}],class:classes,attrs:{"autocomplete":"off","type":"text","name":"provincia","placeholder":"Provincia"},domProps:{"value":(_vm.data.province)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "province", $event.target.value)}}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.data.country == 'España')?_c('div',{staticClass:"contenedor-input half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.province),expression:"data.province"}],staticClass:"pretty",class:classes,attrs:{"id":"provincia","name":"provincia"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.data, "province", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"name":"myBrowser","placeholder":"","value":""}},[_vm._v("Provincia")]),_vm._l((_vm.dominios.provincias),function(provincia,key){return _c('option',{key:key,attrs:{"name":"myBrowser"},domProps:{"value":provincia}},[_vm._v(" "+_vm._s(provincia))])})],2),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"contenedor-input half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.cp),expression:"data.cp"}],class:classes,attrs:{"autocomplete":"off","type":"text","name":"cp","placeholder":"Código Postal"},domProps:{"value":(_vm.data.cp)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "cp", $event.target.value)}}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('h2',{staticClass:"titulo left"},[_vm._v("Perfiles sociales")]),_c('div',{staticClass:"contenedor-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.facebook),expression:"data.facebook"}],attrs:{"autocomplete":"off","type":"text","name":"facebook","placeholder":"Facebook"},domProps:{"value":(_vm.data.facebook)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "facebook", $event.target.value)}}})]),_c('div',{staticClass:"contenedor-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.linkedin),expression:"data.linkedin"}],attrs:{"autocomplete":"off","type":"text","name":"linkedin","placeholder":"Linkedin"},domProps:{"value":(_vm.data.linkedin)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "linkedin", $event.target.value)}}})]),_c('div',{staticClass:"contenedor-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.instagram),expression:"data.instagram"}],attrs:{"autocomplete":"off","type":"text","name":"instagram","placeholder":"Instagram"},domProps:{"value":(_vm.data.instagram)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "instagram", $event.target.value)}}})]),_c('div',{staticClass:"full-width row-center"},[_c('button',{staticClass:"boton-azul",attrs:{"loading":_vm.loading}},[_vm._v("Enviar")])])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }