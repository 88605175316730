var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"microform",attrs:{"edit":_vm.edit}},[(!_vm.edit && _vm.data)?[_c('h3',[_vm._v(_vm._s(_vm.data.sector.name))]),_c('h2',[_vm._v(_vm._s(_vm.data.position)+" en "+_vm._s(_vm.data.company))]),_c('p',[_vm._v(_vm._s(_vm._f("date")(_vm.data.start_date))+" - "),(_vm.data.end_date)?[_vm._v(_vm._s(_vm._f("date")(_vm.data.end_date)))]:_vm._e(),_vm._v(" "),(!_vm.data.end_date)?[_vm._v("Actualidad")]:_vm._e()],2)]:_vm._e(),_c('a',{staticClass:"editar",on:{"click":function($event){_vm.edit = true}}},[_vm._v("editar")]),_c('a',{staticClass:"eliminar",on:{"click":function($event){_vm.showerase = true}}},[_vm._v("Eliminar")]),(_vm.edit)?[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var valid = ref.valid;
return [_c('form',{staticClass:"paso row-between fww",attrs:{"valid":valid,"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('div',{staticClass:"contenedor-input half"},[_c('ValidationProvider',{attrs:{"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.position),expression:"data.position"}],class:classes,attrs:{"autocomplete":"off","type":"text","name":"puesto","placeholder":"Puesto","max":"50"},domProps:{"value":(_vm.data.position)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "position", $event.target.value)}}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input half"},[_c('ValidationProvider',{attrs:{"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.company),expression:"data.company"}],class:classes,attrs:{"autocomplete":"off","type":"text","name":"empresa","placeholder":"Empresa","max":"50"},domProps:{"value":(_vm.data.company)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "company", $event.target.value)}}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input half"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('datepicker',{class:classes,attrs:{"label":'Fecha de Inicio',"name":'fecha'},model:{value:(_vm.data.start_date),callback:function ($$v) {_vm.$set(_vm.data, "start_date", $$v)},expression:"data.start_date"}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input half"},[_c('div',{staticClass:"toggle"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.actual),expression:"data.actual"}],attrs:{"type":"checkbox","id":"actual"},domProps:{"checked":Array.isArray(_vm.data.actual)?_vm._i(_vm.data.actual,null)>-1:(_vm.data.actual)},on:{"change":function($event){var $$a=_vm.data.actual,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.data, "actual", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.data, "actual", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.data, "actual", $$c)}}}}),_c('label',{attrs:{"for":"actual"}},[_vm._v("Actualmente")])]),_c('datepicker',{attrs:{"label":'Fecha de fin',"name":'fecha'},model:{value:(_vm.data.end_date),callback:function ($$v) {_vm.$set(_vm.data, "end_date", $$v)},expression:"data.end_date"}})],1),_c('div',{staticClass:"contenedor-input"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('autocomplete',{class:classes,attrs:{"name":'sector',"label":'Sector',"type":'sector',"idkey":'id'},model:{value:(_vm.data.sector),callback:function ($$v) {_vm.$set(_vm.data, "sector", $$v)},expression:"data.sector"}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"full-width row-center contenedor-botones"},[_c('button',{staticClass:"boton-azul",attrs:{"loading":_vm.loading}},[_vm._v("Guardar")]),_c('button',{staticClass:"boton-gris",on:{"click":_vm.cancel}},[_vm._v("Cancelar")])])])]}}],null,false,1445941382)})]:_vm._e(),(_vm.showerase)?_c('popup-confirmacion',{attrs:{"loading":_vm.loadingerase,"how":'borrarprofesional',"isnegative":true},on:{"accept":_vm.requestErase,"cancel":function($event){_vm.showerase = false}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }