var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"microform",attrs:{"edit":_vm.edit}},[(!_vm.edit && _vm.data)?[_c('h3',[_vm._v(_vm._s(_vm.data.type.name))]),_c('h2',[_vm._v(_vm._s(_vm.data.comment))])]:_vm._e(),(_vm.data.dataId)?_c('a',{staticClass:"editar",on:{"click":function($event){_vm.edit = true}}},[_vm._v("editar")]):_vm._e(),(_vm.data.dataId)?_c('a',{staticClass:"eliminar",on:{"click":function($event){_vm.showerase = true}}},[_vm._v("Eliminar")]):_vm._e(),(_vm.edit)?[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var valid = ref.valid;
return [_c('form',{staticClass:"paso row-between fww",attrs:{"valid":valid,"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('div',{staticClass:"contenedor-input"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('autocomplete',{class:classes,attrs:{"name":'personal_type',"label":'Relación',"type":'personal_type',"idkey":'id'},model:{value:(_vm.data.type),callback:function ($$v) {_vm.$set(_vm.data, "type", $$v)},expression:"data.type"}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('label',{attrs:{"for":"comment"}},[_vm._v("Indica nombre y apellidos de tu familiar o amigo en la UFV*")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.comment),expression:"data.comment"}],class:classes,attrs:{"name":"comment","id":"comment","fill":!!_vm.data.comment},domProps:{"value":(_vm.data.comment)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "comment", $event.target.value)}}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"full-width row-center"},[_c('button',{staticClass:"boton-azul",attrs:{"loading":_vm.loading}},[_vm._v("Guardar")])])])]}}],null,false,760715537)})]:_vm._e(),(_vm.showerase)?_c('popup-confirmacion',{attrs:{"loading":_vm.loadingerase,"how":'borrarfamiliar',"isnegative":true},on:{"accept":_vm.requestErase,"cancel":function($event){_vm.showerase = false}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }