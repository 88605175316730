var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{ref:"image",attrs:{"rules":"validimage"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('div',{staticClass:"image-input",class:classes},[_c('input',{attrs:{"type":"file","id":_vm.id,"name":_vm.name},on:{"change":_vm.handleFileChange}}),(!!!_vm.imagetoshow)?[_c('p',[_vm._v("Arrastrar y soltar o "),_c('a',{staticClass:"btn -white"},[_vm._v("Selecciona archivos")])]),_c('span',{staticClass:"tip"},[_vm._v(".jpg .jpeg .png Tamaño máximo 2MB")])]:_vm._e(),(!!_vm.imagetoshow)?_c('span',{staticClass:"imagen-avatar",style:(("background:url(" + _vm.imagetoshow + ") center/cover no-repeat"))}):_vm._e(),(!!_vm.imagetoshow)?_c('a',{staticClass:"btn"},[_vm._v("Cambiar")]):_vm._e(),_c('span',_vm._b({staticClass:"error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])],2),(_vm.show)?_c('div',{staticClass:"cropper-container"},[_c('div',{staticClass:"popup"},[(!!_vm.originalimage)?_c('cropper',{attrs:{"classname":"cropper","src":_vm.originalimage,"stencil-props":{
                aspectRatio: 1/1
            },"stencilComponent":_vm.$options.components.CircleStencil,"resizeImage":true},on:{"change":_vm.change}}):_vm._e(),(false)?_c('CircleStencil'):_vm._e(),_c('div',{staticClass:"contenedor-botones row-center"},[_c('a',{staticClass:"boton-azul",on:{"click":_vm.save}},[_vm._v("Guardar")]),_c('a',{staticClass:"boton-gris"},[_vm._v("Cancelar")])])],1)]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }