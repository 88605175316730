var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"microform",attrs:{"edit":_vm.edit}},[(!_vm.edit && _vm.data)?[_c('h3',[_vm._v(_vm._s(_vm.dominios.nivelesidioma[_vm.data.level]))]),_c('h2',[_vm._v(_vm._s(_vm.data.lang.name))])]:_vm._e(),(_vm.data.dataId)?_c('a',{staticClass:"editar",on:{"click":function($event){_vm.edit = true}}},[_vm._v("editar")]):_vm._e(),(_vm.data.dataId)?_c('a',{staticClass:"eliminar",on:{"click":function($event){_vm.showerase = true}}},[_vm._v("Eliminar")]):_vm._e(),(_vm.edit)?[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var valid = ref.valid;
return [_c('form',{staticClass:"paso row-between fww",attrs:{"valid":valid,"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('div',{staticClass:"contenedor-input -middle"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('autocomplete',{class:classes,attrs:{"name":'langs',"label":'Idioma',"type":'langs',"idkey":'id'},model:{value:(_vm.data.lang),callback:function ($$v) {_vm.$set(_vm.data, "lang", $$v)},expression:"data.lang"}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input -middle"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.level),expression:"data.level"}],staticClass:"pretty",class:classes,attrs:{"id":"nivelidioma","name":"nivelidioma"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.data, "level", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"name":"myBrowser","placeholder":"","value":""}},[_vm._v("Nivel")]),_vm._l((_vm.dominios.nivelesidioma),function(idioma,key){return _c('option',{key:key,attrs:{"name":"myBrowser"},domProps:{"value":key}},[_vm._v(" "+_vm._s(idioma))])})],2),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"full-width row-center"},[_c('button',{staticClass:"boton-azul",attrs:{"loading":_vm.loading}},[_vm._v("Guardar")])])])]}}],null,false,3266900080)})]:_vm._e(),(_vm.showerase)?_c('popup-confirmacion',{attrs:{"loading":_vm.loadingerase,"how":'borraridioma',"isnegative":true},on:{"accept":_vm.requestErase,"cancel":function($event){_vm.showerase = false}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }