var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper-registro cover-login"},[_c('div',{staticClass:"popup"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var valid = ref.valid;
return [_c('form',{staticClass:"paso row-between fww",attrs:{"valid":valid,"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('h2',{staticClass:"titulo left"},[_vm._v("Mis datos Internacional")]),_c('div',{staticClass:"toggle"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.internacional_bowl),expression:"data.internacional_bowl"}],attrs:{"type":"checkbox","id":"internacional"},domProps:{"checked":Array.isArray(_vm.data.internacional_bowl)?_vm._i(_vm.data.internacional_bowl,null)>-1:(_vm.data.internacional_bowl)},on:{"change":[function($event){var $$a=_vm.data.internacional_bowl,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.data, "internacional_bowl", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.data, "internacional_bowl", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.data, "internacional_bowl", $$c)}},_vm.requestSaveAlumni]}}),_c('label',{attrs:{"for":"internacional"}},[_vm._v("Soy alumno internacional")])]),(_vm.data.internacional_bowl)?[_c('div',{staticClass:"contenedor-input row-start fww"},[_c('h5',[_vm._v("¿Cuál es tu relación con la UFV?")]),_vm._l((_vm.dominios.relacionufv),function(n,key){return _c('div',{key:key,staticClass:"full"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.international_relation),expression:"data.international_relation"}],attrs:{"type":"radio","name":"international_relation","id":'relacion-'+key},domProps:{"value":key,"checked":_vm._q(_vm.data.international_relation,key)},on:{"change":function($event){return _vm.$set(_vm.data, "international_relation", key)}}}),_c('label',{attrs:{"for":'relacion-'+key}},[_vm._v(_vm._s(n))])])})],2),_c('div',{staticClass:"contenedor-input"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('year-datepicker',{class:classes,attrs:{"label":'¿Cuándo participaste o hiciste tu intercambio con la UFV?',"minpage":new Date(),"name":'international_year'},model:{value:(_vm.data.international_year),callback:function ($$v) {_vm.$set(_vm.data, "international_year", $$v)},expression:"data.international_year"}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"contenedor-input"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.internaltional_buddy),expression:"data.internaltional_buddy"}],class:classes,attrs:{"autocomplete":"off","type":"text","name":"buddy","placeholder":"Indícanos el nombre de tu buddy en la UFV"},domProps:{"value":(_vm.data.internaltional_buddy)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "internaltional_buddy", $event.target.value)}}}),_c('span',_vm._b({staticClass:"custom-error"},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"full-width row-center"},[_c('button',{staticClass:"boton-azul",attrs:{"loading":_vm.loading}},[_vm._v("Guardar")])]),_c('figure',[_vm._v("Mis idiomas")]),_vm._l((_vm.langs),function(el,key){return _c('idioma',{key:key,attrs:{"values":el}})}),(_vm.langs.length == 0 && !_vm.shownew)?_c('p',[_vm._v("Aún no tienes ningún idioma añadido")]):_vm._e(),(!_vm.shownew)?_c('div',{staticClass:"contenedor-boton full-width row-center"},[_c('a',{staticClass:"boton-azul",attrs:{"loading":_vm.loading},on:{"click":function($event){_vm.shownew = true}}},[_vm._v("Añadir nuevo")])]):_vm._e(),(_vm.shownew)?_c('idioma',{attrs:{"showedit":true},on:{"cancel":function($event){_vm.shownew = false},"refresh":_vm.setLangs}}):_vm._e()]:_vm._e()],2)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }